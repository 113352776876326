import React, {
  useEffect,
  useRef,
  useCallback
} from 'react';

import Layout from "../components/layout.js";
import Hero from "../components/hero.js";

import AppleAppStoreBageImg from "../images/apple-app-store-badge-outline.svg";
import GooglePlayBadgeImg from "../images/google-play-badge-outline.svg";
import AmbiaButtonImg from "../images/ambia-logo.svg";

// import * as React from "react"



// styles
const pageStyles = {
  color: "white",
  padding: "5vmax",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",



  // height: "100%",
  // margin: 0,
}
const contentStyle = {
  // minHeight: "100%",
  // marginBottom: "-50px",
} 
const stickyAtBottom = {
  // height: "50px",
}



const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: "64vw",
  color: "white"
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const stepByStep = {
  textAlign: "center"
}

const appBadge = {
  maxWidth: "80vw",
  width: "256px",
  margin: "0 auto",
  padding: "2vmax",
}

const appIconAndroid = {
  ...appBadge,
  paddingTop: "0.25vmax",
}
const appIconiOS = {
  ...appBadge,
  paddingBottom: "0.25vmax",
}
const appIconAmbia = {
  ...appBadge,
  paddingBottom: "5vmax",
  width: "77px",
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const legalListItemStyles = {
  display: "inline-block",
  margin: 0,
  padding: "12px",
  minWidth: "256px",
}

const linkStyle = {
  color: "white",
  textDecoration: "none", 
  ":hover": {
    textDecoration: 'underline',
  },
  "&:hover": {
    textDecoration: 'underline',
  },
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const legalLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const logoStyle = {
  maxWidth: 60,
  textAlign: "center"
}

const footerStyle = {
  display: "block"
} 

const logoFooter = {
   maxWidth: "80vw",
   margin: "0 auto",
}

const legalFooter = {

}

const logoInFooterStyle = {
  width: "12%",
  padding: "4%",
  display: "inline-block",
  // white: 
  filter: "brightness(0) invert(1)",
  // Chocolarium brown:
  // filter: "invert(12%) sepia(7%) saturate(5182%) hue-rotate(334deg) brightness(96%) contrast(89%)" 
} 

const legalFooterStyle = {
  display: "block"
} 

const legalListStyles = {
  marginBottom: 96,
  paddingLeft: 0,
  display: "block",
  width: "fit-content",
  margin: "0 auto", 
  maxWidth: "80vw", 
  overflow: "hidden",
}


const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}


/* Rectangle 22 */
const meshGradient1 = {
  pointerEvents: "none",
  userSelect: "none",
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "linear-gradient(168.21deg, #3D2E55 0%, #87106F 101.89%)",
  zIndex: -10,
  height: "100%",
}


/* Rectangle 23 */
const meshGradient2 = {
  pointerEvents: "none",
  userSelect: "none",
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "linear-gradient(152.18deg, rgba(0, 194, 255, 0.21) 14.45%, rgba(56, 34, 102, 0) 94.45%)",
  zIndex: -10,
  height: "100%",
}

/* Rectangle 24 */

const meshGradient3 = {
  pointerEvents: "none",
  userSelect: "none",
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "linear-gradient(33.41deg, rgba(255, 77, 0, 0.2) 10.86%, rgba(255, 136, 85, 0) 50.99%)",
  zIndex: -10,
  height: "100%",
}





// markup
const IndexPage = () => {

  return (
    <Layout>
        <title>Erlebe Maestrani's Chocolarium auf eine neue Art - Ambia</title>
        
        <h1 style={headingStyles}>
          Erlebe Maestrani's Chocolarium auf eine <Hero>neue</Hero> Art
        </h1>

        <div style={stepByStep}>
          <div>1. Ambia-App installieren</div>
          <div style={appIconiOS}><a aria-label="Ambia im Apple App-Store" title="Ambia im Apple App-Store" href="https://install.appcenter.ms/orgs/gbanga/apps/arena-1/distribution_groups/maestrani"><img alt="Logo Apple App Store" src={AppleAppStoreBageImg} /></a></div>
          <div style={appIconAndroid}><a aria-label="Ambia im Google Play-Store" title="Ambia im Google Play-Store" href="https://install.appcenter.ms/orgs/gbanga/apps/arena/distribution_groups/maestrani"><img alt="Logo Google Play Store" src={GooglePlayBadgeImg} /></a></div>
          <div>2. Ambia-Knopf drücken, um zu starten</div>
          <div style={appIconAmbia}><img src={AmbiaButtonImg} /></div>
        </div>
    </Layout>
  )
}

export default IndexPage
